import { BaseRequestService } from './base-request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BlackboardLogBean,
  ChartMaintenanceCountBean,
  ChartOrderDistributionBean,
  CreateKioskRequestBean,
  CustomerLogBean,
  CustomizationCountByMonthChartBean,
  DeviceBean,
  DeviceErrorStatBean,
  DeviceIngredientStateBean,
  DeviceMessageBean,
  DeviceOperationType,
  DeviceStateHistoryBean,
  DiagnosticsStepSequenceBean,
  EntityNameBean, FinancialMetricsBean,
  HardwareProvisioningUpdateRequestBean,
  IngredientWeightChartBean,
  JumbotronVideoBean,
  KioskAssetBean,
  KioskBean,
  KioskContentBean,
  KioskInfoBean,
  KioskSnapshotBean,
  KioskState,
  KioskStateHistoryBean,
  KioskStatsBean,
  KioskWorkingHoursBean,
  LogMessageBean, ManageKioskDescriptionBean,
  OrderThickness,
  PageBean,
  RecipeCountByMonthChartBean,
  ResponseBean,
  ResponseItemBean,
  ServiceEventBean,
  SetIngredientsLevelBean,
  SimDeviceProcessBean,
  StepStateBean,
  TemperatureChartBean,
  UpdateKioskRequestBean,
  WorkingHoursBean
} from '../model/model';
import { publishReplay, refCount } from 'rxjs/operators';
import hash from 'object-hash';

@Injectable()
export class KioskService extends BaseRequestService {
  private activeKioskNames: Observable<any>;
  private kioskInfos: Observable<any>;
  private financialMetrics: Observable<any>[] = [];

  private static buildPath(kiosk: KioskBean): string {
    if (!kiosk) {
      return '';
    }
    return KioskService.path(kiosk.id, kiosk.operatorId);
  }

  private static path(kioskId: number, operatorId: number): string {
    return 'admin/kiosk/' + kioskId + '/location/' + operatorId + '/';
  }

  addKiosk(kiosk: KioskBean): Observable<ResponseItemBean<number>> {
    return this.post('admin/kiosk/add', kiosk);
  }

  updateKiosk(kiosk: KioskBean): Observable<ResponseBean> {
    return this.put('admin/kiosk/update', kiosk);
  }

  getKiosk(kioskId: number): Observable<ResponseItemBean<KioskBean>> {
    return this.get('admin/kiosk/' + kioskId);
  }

  copyKiosk(kioskId: number, kioskName: string, operatorId: number = 0): Observable<ResponseItemBean<KioskBean>> {
    const p = {};
    p['kiosk_name'] = kioskName;
    if (operatorId > 0) {
      p['operator_id'] = operatorId;
    }
    return this.get('admin/kiosk/' + kioskId + '/copy', p);
  }

  getKiosks(locationId: number): Observable<PageBean<KioskBean>> {
    return this.get('admin/kiosk/location/' + locationId + '/all_kiosks');
  }

  getAllKiosks(steps: boolean = true): Observable<ResponseItemBean<KioskBean[]>> {
    const p = {};
    p['steps'] = steps;
    return this.get('admin/kiosk/list', p);
  }

  getKiosksInfo(): Observable<ResponseItemBean<KioskInfoBean[]>> {
    if (!this.kioskInfos) {
      this.kioskInfos = this.get('admin/kiosk/list_info').pipe(
        publishReplay(1),
        refCount());
    }
    return this.kioskInfos;
  }

  cloneKioskManufacture(body: CreateKioskRequestBean): Observable<ResponseItemBean<EntityNameBean>> {
    return this.post(`kiosk/manufacture`, body);
  }

  getKioskManufacture(id: number): Observable<ResponseItemBean<KioskAssetBean>> {
    return this.get(`kiosk/manufacture/${id}`);
  }

  updateKioskManufacture(id: number, kiosk: UpdateKioskRequestBean): Observable<ResponseBean> {
    return this.put(`kiosk/manufacture/${id}/update`, kiosk);
  }

  updateProvisioning(id: number, devices: HardwareProvisioningUpdateRequestBean[]): Observable<ResponseBean> {
    return this.put(`kiosk/manufacture/${id}/update_provisioning`, devices);
  }

  getDeviceNames(id: number): Observable<ResponseItemBean<EntityNameBean[]>> {
    return this.get(`admin/kiosk/${id}/device/list/names`);
  }

  updateDeviceAsset(deviceId: number, ids: number[]): Observable<ResponseBean> {
    return this.put(`kiosk/manufacture/device/${deviceId}/update_assets`, ids);
  }

  getActiveKiosksNames(production: boolean = false): Observable<ResponseItemBean<EntityNameBean[]>> {
    if (!this.activeKioskNames) {
      const p = {};
      p['production'] = production;
      this.activeKioskNames = this.get('admin/kiosk/list_active', p).pipe(
        publishReplay(1),
        refCount());
    }
    return this.activeKioskNames;
  }

  deleteKiosk(kiosk: KioskBean): Observable<ResponseBean> {
    return this.del(KioskService.buildPath(kiosk) + 'delete');
  }

  setDiagnosticsState(kioskId: number, enable: boolean): Observable<ResponseItemBean<KioskState>> {
    const p = {};
    p['enable'] = enable;
    return this.get('admin/kiosk/' + kioskId + '/diagnostics', p);
  }

  setSimulatorState(kioskId: number, operatorId: number, enable: boolean): Observable<ResponseItemBean<KioskState>> {
    const p = {};
    p['enable'] = enable;
    return this.get(KioskService.path(kioskId, operatorId) + 'simulator', p);
  }

  updateDeviceProcessTime(kioskId: number, operatorId: number, processes: SimDeviceProcessBean[]): Observable<ResponseBean> {
    return this.put(KioskService.path(kioskId, operatorId) + 'update_device_process_time', processes);
  }

  getDeviceProcessTime(kioskId: number, operatorId: number): Observable<ResponseItemBean<SimDeviceProcessBean[]>> {
    return this.get(KioskService.path(kioskId, operatorId) + 'device_process_time');
  }

  sendDiagnosticsStepSequence(kioskId: number, operatorId: number, step: DiagnosticsStepSequenceBean, repeatCount: number): Observable<ResponseBean> {
    const p = {};
    p['repeat_count'] = repeatCount;
    return this.get(KioskService.path(kioskId, operatorId) + 'diagnostics/execute_sequence/' + step.id, p);
  }

  executeOperationsSequence(kioskId: number, assetId: number, sequenceId: number): Observable<ResponseBean> {
    return this.get(`admin/kiosk/${kioskId}/operations_qa/asset/${assetId}/execute_sequence/${sequenceId}`);
  }

  sendDiagnosticsStep(kioskId: number, operatorId: number, stepJson: string): Observable<ResponseBean> {
    return this.post(KioskService.path(kioskId, operatorId) + 'send_diagnostics_step', stepJson);
  }

  changeDiagnosticsStates(kiosk: KioskBean): Observable<ResponseBean> {
    const p = {};
    p['auto_pickup'] = kiosk.autoPickup;
    p['auto_refill'] = kiosk.autoRefill;
    p['auto_reset'] = kiosk.autoReset;
    p['pseudo_sim_errors'] = kiosk.pseudoSimErrors;
    p['robot_sim'] = kiosk.robotSim;
    p['robot_auto_recovery'] = kiosk.robotAutoRecovery;
    return this.get(KioskService.buildPath(kiosk) + 'change_diagnostics_states', p);
  }

  updateDevice(kioskId: number, operatorId: number, device: DeviceBean): Observable<ResponseBean> {
    return this.put(KioskService.path(kioskId, operatorId) + 'device/update', device);
  }

  getDevice(kiosk: KioskBean, deviceId: number): Observable<ResponseItemBean<DeviceBean>> {
    return this.get(KioskService.buildPath(kiosk) + 'device/' + deviceId);
  }

  executeShellCommand(kioskId: number, deviceId: number, command: string): Observable<ResponseItemBean<string>> {
    return this.get('admin/kiosk/' + kioskId + '/device/' + deviceId + '/execute_shell_command?cmd=' + command);
  }

  getDevices(kioskId: number, limit?: number, offset?: number): Observable<PageBean<DeviceBean>> {
    return this.get('admin/kiosk/' + kioskId + '/device/list', BaseRequestService.buildOffsetParams(limit, offset));
  }

  getDeviceStateHistory(kiosk: KioskBean, deviceId: number, limit?: number, offset?: number):
    Observable<PageBean<DeviceStateHistoryBean>> {
    return this.get(KioskService.buildPath(kiosk) + 'device/' + deviceId + '/state_history/list',
      BaseRequestService.buildOffsetParams(limit, offset));
  }

  deleteDeviceStateHistory(kiosk: KioskBean, deviceId: number, history: DeviceStateHistoryBean): Observable<ResponseBean> {
    return this.del(KioskService.buildPath(kiosk) + 'device/' + deviceId + '/state_history/' + history.id + '/delete');
  }

  clearSynchronizationErrors(kiosk: KioskBean): Observable<ResponseBean> {
    return this.get(KioskService.buildPath(kiosk) + 'clear_sync_errors');
  }

  getSnapshots(locationId: number, kioskId: number, startTime: number, endTime: number): Observable<ResponseItemBean<KioskSnapshotBean>> {

    const p = {};
    p['location_id'] = locationId;
    p['kiosk_id'] = kioskId;
    p['start_time'] = startTime;
    p['end_time'] = endTime;

    return this.get('kiosk/presentation', p);
  }

  getKioskTemperatureCharts(kioskId: number, startTime: number, endTime: number): Observable<ResponseItemBean<TemperatureChartBean[]>> {
    const p = {};
    p['start_time'] = startTime;
    p['end_time'] = endTime;

    return this.get('admin/kiosk/' + kioskId + '/charts/temperature/', p);
  }

  getWorkingHours(kioskId: number, startTime: number, endTime: number): Observable<ResponseItemBean<WorkingHoursBean[]>> {
    const p = {};
    p['start_date'] = startTime;
    p['end_date'] = endTime;

    return this.get('admin/kiosk/' + kioskId + '/working_hours', p);
  }

  setWorkingHours(kioskId: number, value: KioskWorkingHoursBean): Observable<ResponseBean> {
    return this.put('admin/kiosk/' + kioskId + '/working_hours/', value);
  }

  getCustomizationCharts(kioskId: number, duration: string): Observable<ResponseItemBean<CustomizationCountByMonthChartBean[]>> {
    return this.get('admin/kiosk/' + kioskId + '/charts/customizations/recipes/' + duration);
  }

  getCustomizationIngredientsCharts(kioskId: number, recipeId: number, duration: string):
    Observable<ResponseItemBean<CustomizationCountByMonthChartBean[]>> {

    return this.get('admin/kiosk/' + kioskId + '/charts/customizations/recipe/' + recipeId + '/ingredients/' + duration);
  }

  getRecipesCharts(kioskId: number, duration: string): Observable<ResponseItemBean<RecipeCountByMonthChartBean[]>> {

    return this.get('admin/kiosk/' + kioskId + '/charts/recipes/' + duration);
  }

  getIngredientsCharts(kioskId: number, duration: string): Observable<ResponseItemBean<IngredientWeightChartBean[]>> {

    return this.get('admin/kiosk/' + kioskId + '/charts/ingredients/' + duration);
  }

  getMaintenanceCharts(kioskId: number, duration: string): Observable<ResponseItemBean<ChartMaintenanceCountBean[]>> {

    return this.get('admin/kiosk/' + kioskId + '/charts/maintenance/' + duration);
  }

  getTimeCharts(kioskId: number, startTime: number, endTime: number): Observable<ResponseItemBean<ChartOrderDistributionBean>> {

    const p = {};
    p['start_time'] = startTime;
    p['end_time'] = endTime;
    return this.get('admin/kiosk/' + kioskId + '/charts/order_distribution/', p);
  }

  getRecipesChartsCSV(kioskId: number, duration: string): Observable<Blob> {
    return this.blob('admin/kiosk/' + kioskId + '/charts/recipes/' + duration + '/download');
  }

  getMaintenanceChartsCSV(kioskId: number, duration: string): Observable<Blob> {
    return this.blob('admin/kiosk/' + kioskId + '/charts/maintenance/' + duration + '/download');
  }

  getIngredientsChartsCSV(kioskId: number, duration: string): Observable<Blob> {
    return this.blob('admin/kiosk/' + kioskId + '/charts/ingredients/' + duration + '/download');
  }

  getCustomizationChartsCSV(kioskId: number, duration: string): Observable<Blob> {

    return this.blob('admin/kiosk/' + kioskId + '/charts/customizations/recipes/' + duration + '/download');
  }

  getCustomizationIngredientsChartsCSV(kioskId: number, recipeId: number, duration: string): Observable<Blob> {
    return this.blob('admin/kiosk/' + kioskId + '/charts/customizations/recipe/' + recipeId + '/ingredients/' + duration + '/download');
  }

  getKioskTemperatureChartsCSV(kioskId: number, startTime: number, endTime: number, scale: string): Observable<Blob> {
    const p = {};
    p['start_time'] = startTime;
    p['end_time'] = endTime;
    p['scale'] = scale;

    return this.blob('admin/kiosk/' + kioskId + '/charts/temperature/download', p);
  }

  refillIngredient(kioskId: number, deviceId: number): Observable<ResponseBean> {
    return this.get('admin/kiosk/' + kioskId + '/device/' + deviceId + '/refill_ingredient');
  }

  unlockDevice(kioskId: number, deviceId: number): Observable<ResponseBean> {
    return this.get('admin/kiosk/' + kioskId + '/device/' + deviceId + '/unlock');
  }

  getKioskStats(): Observable<KioskStatsBean[]> {
    return this.get('admin/kiosk/stats');
  }

  getDeviceMessages(): Observable<DeviceMessageBean[]> {
    return this.get('admin/kiosk/device_messages');
  }

  getHWLogs(kioskId: number, operatorId: number, startDate: number, endDate: number, query: string): Observable<ResponseItemBean<LogMessageBean[]>> {
    const p = {};
    p['start_date'] = startDate;
    p['end_date'] = endDate;
    if (query) {
      p['query'] = query;
    }
    return this.get(KioskService.path(kioskId, operatorId) + 'device/hw_logs', p);
  }

  requestRobotPose(kioskId: number, operatorId: number): Observable<ResponseBean> {
    return this.get(KioskService.path(kioskId, operatorId) + 'request_robot_pose');
  }

  restartKiosk(kioskId: number, operatorId: number): Observable<ResponseBean> {
    return this.get(KioskService.path(kioskId, operatorId) + 'restart');
  }

  toggleTakingOrders(kioskId: number, paused: boolean): Observable<ResponseBean> {
    return this.getWithKioskToken(kioskId, 'admin/kiosk/' + kioskId + '/set_paused', 1, { paused });
  }

  resetKiosk(kioskId: number): Observable<ResponseBean> {
    return this.get('admin/kiosk/' + kioskId + '/reset_kiosk');
  }

  placeOrders(kioskId: number, cnt: number, thickness: OrderThickness): Observable<ResponseBean> {
    const p = {};
    p['number'] = cnt;
    p['thickness'] = thickness;
    return this.get('admin/kiosk/' + kioskId + '/place_random_orders', p);
  }

  updateDeviceSW(kioskId: number, operatorId: number): Observable<ResponseBean> {
    return this.get(KioskService.path(kioskId, operatorId) + 'update_device_sw');
  }

  updateConfig(kioskId: number): Observable<ResponseBean> {
    return this.get('admin/kiosk/' + kioskId + '/notify_config_update');
  }

  getDiagnosticsStepsHistory(kioskId: number, operatorId: number, startTime: number, endTime: number, limit?: number, offset?: number):
    Observable<PageBean<StepStateBean>> {
    const p = BaseRequestService.buildOffsetParams(limit, offset);
    if (startTime != null) {
      p['startTime'] = startTime;
      p['endTime'] = endTime;
    }
    return this.get(KioskService.path(kioskId, operatorId) + 'diagnostics_steps_history', p);
  }

  getKioskContent(kioskId: number): Observable<ResponseItemBean<KioskContentBean>> {
    return this.get('kiosk/' + kioskId + '/content');
  }

  changeDevicesOperationType(kioskId: number, state: DeviceOperationType): Observable<ResponseBean> {
    const p = {};
    p['type'] = state;
    return this.get('admin/kiosk/' + kioskId + '/devices/change_operation_type', p);
  }

  getDispenseStepsHistory(kioskId: number, operatorId: number, startTime: number, endTime: number, deviceOpType: DeviceOperationType,
                          limit?: number, offset?: number): Observable<PageBean<StepStateBean>> {
    const p = BaseRequestService.buildOffsetParams(limit, offset);
    if (startTime != null) {
      p['startTime'] = startTime;
      p['endTime'] = endTime;
      p['deviceOpType'] = deviceOpType;
    }

    return this.get(KioskService.path(kioskId, operatorId) + 'dispense_steps_history', p);
  }

  getStateHistory(kioskId: number, query: string, startTime: number, endTime: number): Observable<ResponseItemBean<KioskStateHistoryBean[]>> {
    const p = [];
    if (query) {
      p['query'] = query;
    }
    if (startTime != null) {
      p['startTime'] = startTime;
      p['endTime'] = endTime;
    }

    return this.get('admin/kiosk/' + kioskId + '/state_history', p);
  }

  getCustomerLogs(kioskId: number, query: string, startTime: number, endTime: number): Observable<ResponseItemBean<CustomerLogBean[]>> {
    const p = [];
    if (query) {
      p['query'] = query;
    }
    if (startTime != null) {
      p['startTime'] = startTime;
      p['endTime'] = endTime;
    }
    return this.get('admin/kiosk/' + kioskId + '/customer_logs', p);
  }

  getServiceEvents(kioskId: number[], startTime: number, endTime: number, manuallyCreated: boolean, category: string = null): Observable<ResponseItemBean<ServiceEventBean[]>> {
    const p = [];
    if (startTime != null) {
      p['startDate'] = startTime;
      p['endDate'] = endTime;
    }
    if (category != null) {
      p['category'] = category;
    }
    p['manuallyCreated'] = manuallyCreated;
    p['kioskId'] = kioskId;
    return this.get('admin/service_events', p);
  }

  updateServiceEvent(kioskId: number, event: ServiceEventBean): Observable<ResponseBean> {
    return this.putWithKioskToken(kioskId, 1, 'admin/service_events/kiosk/' + kioskId + '/event/' + event.id, event);
  }

  addServiceEvent(kioskId: number, event: ServiceEventBean): Observable<ResponseBean> {
    return this.post('admin/service_events/kiosk/' + kioskId, event);
  }

  getServiceEventCount(kiosks: KioskInfoBean[], startTime: number, endTime: number, period: string, category: string): Observable<ResponseItemBean<{
    [p: number]: number
  }>> {
    let p = this.timeRangeParams(startTime, endTime, period);
    if (category) {
      p['category'] = category;
    }
    return this.get(this.multipleKioskUrl('admin/service_events/chart/count?', kiosks), p);
  }

  getServiceEventDuration(kiosks: KioskInfoBean[], startTime: number, endTime: number, period: string, category: string): Observable<ResponseItemBean<{
    [p: number]: number
  }>> {
    let p = this.timeRangeParams(startTime, endTime, period);
    if (category) {
      p['category'] = category;
    }
    return this.get(this.multipleKioskUrl('admin/service_events/chart/duration?', kiosks), p);
  }

  getServiceEventsCountByCategory(kiosks: KioskInfoBean[], startTime: number, endTime: number, period: string, category: string): Observable<ResponseItemBean<{
    [p: string]: number
  }>> {
    let p = this.timeRangeParams(startTime, endTime, period);
    if (category) {
      p['category'] = category;
    }
    return this.get(this.multipleKioskUrl('admin/service_events/chart/count_by_category?', kiosks), p);
  }

  getServiceEventsDurationByCategory(kiosks: KioskInfoBean[], startTime: number, endTime: number, period: string, category: string): Observable<ResponseItemBean<{
    [p: string]: number
  }>> {
    let p = this.timeRangeParams(startTime, endTime, period);
    if (category) {
      p['category'] = category;
    }
    return this.get(this.multipleKioskUrl('admin/service_events/chart/duration_by_category?', kiosks), p);
  }

  getServiceEventsDurationBySubComponentTrend(kiosks: KioskInfoBean[], startTime: number, endTime: number, period: string, category: string): Observable<ResponseItemBean<{
    [p: number]: { [p: string]: number }
  }>> {
    let p = this.timeRangeParams(startTime, endTime, period);
    if (category) {
      p['category'] = category;
    }
    return this.get(this.multipleKioskUrl('admin/service_events/chart/duration_by_sub_component_trend?', kiosks), p);
  }

  getServiceEventsDurationByCategoryTrend(kiosks: KioskInfoBean[], startTime: number, endTime: number, period: string): Observable<ResponseItemBean<{
    [p: number]: { [p: string]: number }
  }>> {
    return this.get(this.multipleKioskUrl('admin/service_events/chart/duration_by_category_trend?', kiosks), this.timeRangeParams(startTime, endTime, period));
  }

  getServiceEventsCountByCategoryTrend(kiosks: KioskInfoBean[], startTime: number, endTime: number, period: string): Observable<ResponseItemBean<{
    [p: number]: { [p: string]: number }
  }>> {
    return this.get(this.multipleKioskUrl('admin/service_events/chart/count_by_category_trend?', kiosks), this.timeRangeParams(startTime, endTime, period));
  }

  multipleKioskUrl(url: string, kiosks: KioskInfoBean[]) {

    if (kiosks && kiosks.length > 0) {
      for (const kiosk of kiosks) {
        if (kiosk.kioskId != 0) {
          url += ('kioskId=' + kiosk.kioskId + '&');
        }
      }
    }

    url = url.replace(/&\s*$/, '');
    return url;
  }

  timeRangeParams(startTime: number, endTime: number, period: string) {
    const p = {};
    if (startTime != null) {
      p['startTime'] = startTime;
    }
    if (endTime != null) {
      p['endTime'] = endTime;
    }
    if (period != null) {
      p['period'] = period;
    }
    return p;
  }

  testNetworkSpeed(kioskId: number): Observable<ResponseItemBean<string>> {
    return this.get('admin/kiosk/' + kioskId + '/test_network_speed');
  }

  updateIngredientPercent(kioskId: number, deviceId: number, ingredientId: number, value: number): Observable<ResponseItemBean<string>> {
    return this.get('kiosk/' + kioskId + '/device/' + deviceId + '/ingredient/' + ingredientId + '/value?value=' + value);
  }

  getDeviceIngredients(kioskId: number, deviceId: number): Observable<ResponseItemBean<DeviceIngredientStateBean[]>> {
    return this.get('admin/kiosk/' + kioskId + '/device/' + deviceId + '/ingredients/');
  }

  getDeviceErrors(kioskId: number, deviceId: number, hourly: boolean): Observable<ResponseItemBean<DeviceErrorStatBean[]>> {
    return this.get('admin/kiosk/' + kioskId + '/device/' + deviceId + '/error_stats', { hourly });
  }

  setDeviceIngredientState(kioskId: number, deviceId: number, ingredientId: number, enable: boolean): Observable<ResponseBean> {
    return this.get(`admin/kiosk/${kioskId}/device/${deviceId}/ingredient/${ingredientId}/state?enable=${enable}`);
  }

  updateIngredientLevel(bean: SetIngredientsLevelBean): Observable<ResponseItemBean<string>> {
    return this.postWithKioskToken(bean.kioskId, 1, 'kiosk/ingredient_level', bean);
  }

  getJumbotronVideos(kioskId: number): Observable<ResponseItemBean<JumbotronVideoBean[]>> {
    return this.get('admin/kiosk/' + kioskId + '/jumbotron/video');
  }

  playJumbotronVideo(kioskId: number, bean: JumbotronVideoBean): Observable<ResponseBean> {
    return this.post('admin/kiosk/' + kioskId + '/jumbotron/play_video', bean);
  }

  getRobotDances(kioskId: number): Observable<ResponseItemBean<EntityNameBean[]>> {
    return this.get('admin/kiosk/' + kioskId + '/robot/dance');
  }

  startRobotDance(kioskId: number, id: number): Observable<ResponseBean> {
    return this.get('admin/kiosk/' + kioskId + '/robot/dance/' + id);
  }

  clear() {
    this.activeKioskNames = null;
    this.kioskInfos = null;
  }

  getBlackboardLogs(): Observable<ResponseItemBean<BlackboardLogBean>> {
    return this.get('admin/order/blackboard_logs/failure');
  }

  getFinancialMetrics(kiosk: KioskInfoBean, startTime: number, endTime: number, period: string)
    : Observable<ResponseItemBean<FinancialMetricsBean[]>> {
    const p = BaseRequestService.timeParams(startTime, endTime);
    if (period != null) {
      p['period'] = period;
    }

    const url = 'admin/analytics/metrics/financial?' + KioskService.kioskParams([kiosk]);
    const h = hash(p) + url;

    if (!this.financialMetrics[h]) {
      this.financialMetrics[h] = this.getWithKioskToken(kiosk.kioskId, url, 1, p).pipe(
        publishReplay(1),
        refCount());
    }
    return this.financialMetrics[h];
  }

  getDescription(id: number): Observable<ResponseItemBean<ManageKioskDescriptionBean>> {
    return this.getWithKioskToken(id, `admin/kiosk/${id}/manage/description`, 1);
  }

  disableMaintenance(kioskId: number): Observable<ResponseItemBean<ServiceEventBean>> {
    return this.getWithKioskToken(kioskId, `admin/kiosk/${kioskId}/disable_maintenance`, 1);
  }

  enableMaintenance(kioskId: number): Observable<ResponseBean> {
    return this.getWithKioskToken(kioskId, `admin/kiosk/${kioskId}/enable_maintenance`, 1);
  }

  getServiceEventComponents(kioskId: number): Observable<ResponseItemBean<{ components: string[], reasons: string[] }>> {
    return this.getWithKioskToken(kioskId, 'admin/service_events/components', 1);
  }
}
